// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { coerceNumberProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  asapScheduler,
  combineLatest,
  delay,
  distinctUntilChanged,
  filter,
  merge,
  shareReplay,
  switchMap,
  takeUntil,
} from 'rxjs';
import { BigTabComponent } from '../big-tab/big-tab.component';
import { BigTabBorderStyle } from '../big-tabs.model';

@Component({
  selector: 'fsn-big-tab-strip',
  templateUrl: './big-tab-strip.component.html',
  styleUrls: ['./big-tab-strip.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class BigTabStripComponent implements AfterContentInit, OnInit, OnDestroy {
  @HostBinding('class.fs-next') private readonly fsNext = true;
  private readonly destroyed$ = new Subject<void>();
  protected readonly tabs$ = new ReplaySubject<BigTabComponent[]>();
  protected readonly _selectedTab$ = new Subject<BigTabComponent>();
  protected readonly _selectedIndex$ = new BehaviorSubject<number>(0);
  protected readonly selectedTab$ = this._selectedTab$.pipe(
    distinctUntilChanged(),
    shareReplay(1),
    delay(0, asapScheduler),
  );
  protected readonly selectedIndex$ = this._selectedIndex$.pipe(
    distinctUntilChanged(),
    shareReplay(1),
    delay(0, asapScheduler),
  );

  // Basic bindings match mat-tab-group.
  @Input() set selectedIndex(value: string | number) {
    this._selectedIndex$.next(coerceNumberProperty(value));
  }
  @Output() selectedIndexChange = this.selectedIndex$;
  @Output() selectedTabChange = this.selectedTab$;

  @ContentChildren(BigTabComponent) tabs: QueryList<BigTabComponent>;

  @Input() borders = BigTabBorderStyle.DATABOXES;

  @HostBinding('attr.role') readonly role = 'tablist';
  @HostBinding('class.fsn-big-tab-no-borders') get clsNoBorders() {
    return this.borders === BigTabBorderStyle.NONE;
  }
  @HostBinding('class.fsn-big-tab-folio-borders') get clsFolioBorders() {
    return this.borders === BigTabBorderStyle.FOLIO;
  }
  @HostBinding('class.fsn-big-tab-tabbed-folio-borders') get clsTabbedFolioBorders() {
    return this.borders === BigTabBorderStyle.TABBED_FOLIO;
  }
  @HostBinding('class.fsn-big-tab-tab-borders') get clsTabBorders() {
    return this.borders === BigTabBorderStyle.TABS;
  }
  @HostBinding('class.fsn-big-tab-databox-borders') get clsDataboxBorders() {
    return this.borders === BigTabBorderStyle.DATABOXES;
  }

  constructor() {}

  ngOnInit() {
    // React to changes in internal state. There are a number of things that
    // could happen, because we track both the index and the actual selected
    // tab, and the selected index may change programmatically or the tab could
    // be clicked on by the user.
    //
    // These subscriptions respond to each of the ways that changes could occur,
    // and sync those changes to the other observables. The observables are
    // configured to only emit when they actually change, so this quickly
    // reaches a steady state.

    this.tabs$.pipe(takeUntil(this.destroyed$)).subscribe(tabs => {
      const selectedTab = tabs.find(tab => tab.selected);
      if (selectedTab) {
        this._selectedTab$.next(selectedTab);
      }
    });

    combineLatest([this.tabs$, this.selectedTab$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([tabs, selectedTab]) => {
        if (tabs.includes(selectedTab)) {
          this._selectedIndex$.next(tabs.findIndex(tab => tab === selectedTab));
        }
        tabs.forEach(tab => (tab.selected = tab === selectedTab));
      });

    combineLatest([this.tabs$, this.selectedIndex$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([tabs, selectedIndex]) => {
        if (selectedIndex >= 0 && selectedIndex < tabs.length) {
          this._selectedTab$.next(tabs[selectedIndex]);
        }
      });

    // React to changes in child state.
    this.tabs$
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(tabs => merge(...tabs.map(tab => tab.tabSelectRequested))),
        filter(requestedTab => !requestedTab.disabled),
      )
      .subscribe(selectedTab => this._selectedTab$.next(selectedTab));

    // Notify observers watching the tab directly rather than the outputs of this container.
    this.selectedTab$.pipe(takeUntil(this.destroyed$)).subscribe(tab => tab.tabSelected.emit(tab));
  }

  ngAfterContentInit() {
    // ContentChildren only gets set at this point.
    this.tabs.changes.pipe(takeUntil(this.destroyed$)).subscribe(tabs => this.tabsChanged(tabs.toArray()));
    this.tabsChanged(this.tabs.toArray());
  }

  tabsChanged(tabs: BigTabComponent[]) {
    tabs.forEach((tab, index) => (tab.index = index));
    this.tabs$.next(tabs);
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
