// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

export interface MenuData {
  name: string;
  value: string;
  selected: boolean;
}

@Component({
  selector: 'app-nav-dot-tab-strip',
  templateUrl: './nav-dot-tab-strip.component.html',
  styleUrls: ['./nav-dot-tab-strip.component.scss'],
})
export class NavDotTabStripComponent implements OnChanges {
  @Input()
  selectedNavItem = null;

  @Input()
  navData = null;

  @Output()
  navItemSelected: EventEmitter<string> = new EventEmitter<string>();

  menuData: MenuData[];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.menuData = this.navData.map(d => ({
      name: d.step,
      value: d.key,
      selected: d.key === changes.selectedNavItem.currentValue,
    }));
  }

  onNavItemSelected(event: string) {
    this.navItemSelected.emit(event);
  }
}
