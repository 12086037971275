// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Component, computed, input } from '@angular/core';
import { ERiskRating } from 'src/app/company-record/company-record.model';
import {
  getRecommendation,
  getRecommendationColour,
  getRecommendationDisplay,
} from 'src/app/questionnaire-response/questionnaire-results/saq.model';

@Component({
  selector: 'app-recommendation',
  template: `@if (hasValue()) {
      <section class="d-flex">
        <span class="action-indicator">
          <mat-icon svgIcon="box" [style.color]="recommendationColour" />
          {{ recommendationDisplay }}
        </span>
      </section>
    } @else {
      -
    }`,
  styleUrls: ['./recommendation.component.scss'],
})
export class RecommendationComponent {
  rating = input<ERiskRating>();
  mitigationScore = input<number>();

  protected recommendationDisplay: string = '';
  protected recommendationColour: string = '';

  hasValue = computed(() => {
    const rating = this.rating();
    const mitigationScore = this.mitigationScore();

    // with valid inputs attempt to fetch recommendation text to display and status colour
    if (rating && typeof mitigationScore === 'number' && !isNaN(mitigationScore)) {
      const recommendation = getRecommendation(rating, mitigationScore);
      this.recommendationDisplay = recommendation !== null ? getRecommendationDisplay(recommendation) : '';
      this.recommendationColour = recommendation !== null ? getRecommendationColour(recommendation) : '';
      return true;
    }
    return false;
  });
}
