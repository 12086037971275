// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { AlertService } from 'src/app/shared/alert.service';
import { User } from 'src/app/user/user.model';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss'],
})
export class AdminUsersComponent implements OnChanges, AfterViewInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  @ViewChild('userDetailsCell', { static: false })
  userDetailsCell: TemplateRef<HTMLElement>;

  @ViewChild('deleteButtonCell', { static: false })
  deleteButtonCell: TemplateRef<HTMLElement>;

  @Input()
  users: User[];

  @Input()
  allowRemove = false;

  @Input()
  confirmDeleteMessage = 'Are you sure you want to delete this user?';

  @Input()
  deleteSuccessMessage = 'User deleted successfully';

  /**
   * Action to perform when clicking on a user. Only the parent component knows what to do for the action e.g. add user to a client or to a company.
   *
   * If we only wish to navigate and view the clicked user, use {@link selectedRouterLinkPrefix} instead to preserve HTML ``<a>`` with ``href``
   */
  @Output()
  selected = new EventEmitter<User>();

  @Output()
  removed = new EventEmitter<User>();

  /**
   * Optional route prefix to go to for each user, when the user is clicked. By specifying this, a ``href`` is created on the ``<a>`` element for each user.
   *
   * This allows for right click on the user link and opening it in a new tab.
   */
  @Input()
  selectedRouterLinkPrefix: string = null;

  dtOptions: DataTables.Settings = null;

  viewRendered = false;
  modalVisible = false;
  selectedForRemove: User;

  constructor(private readonly alertService: AlertService) {}

  ngAfterViewInit() {
    this.viewRendered = true;
    setTimeout(() => {
      this.dtOptions = {
        data: this.users,
        columns: this.getColumns().concat(this.allowRemove ? this.getRemoveColumn() : []),
        order: [[1, 'asc']],
      };
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.viewRendered) {
      this.dtOptions = {
        data: changes.users.currentValue,
        columns: this.getColumns().concat(this.allowRemove ? this.getRemoveColumn() : []),
      };
    }
  }

  select(user: User): void {
    this.selected.emit(user);
  }

  delete(user: User): void {
    this.selectedForRemove = user;
    $('#removeModal').modal('show');
  }

  doDelete(): void {
    this.removed.emit(this.selectedForRemove);
    this.selectedForRemove = null;
    $('#removeModal').modal('hide');
    this.alertService.showSuccess(this.deleteSuccessMessage);
  }

  cancel() {
    this.selectedForRemove = null;
    $('#removeModal').modal('hide');
  }

  getUserName(user: User) {
    if (user) {
      return `${user.firstName || ''} ${user.lastName || ''}`.trim();
    } else {
      return '';
    }
  }

  private getColumns(): ADTColumns[] {
    return [
      {
        // ID
        data: (row: User, type: string, set: unknown, meta: DataTables.CellMetaSettings) => row.id || 'No id',
        className: 'align-middle',
      },
      {
        // Name
        data: (row: User) => `${row.firstName} ${row.lastName}`,
        className: 'align-middle',
        ngTemplateRef: {
          ref: this.userDetailsCell,
        },
      },
      {
        // User Name
        data: (row: User) => row.username,
        className: 'align-middle',
      },
      {
        // Email
        data: (row: User) => row.email,
        className: 'align-middle',
      },
    ];
  }

  private getRemoveColumn(): ADTColumns[] {
    return [
      {
        // Remove
        data: (row: User) => row,
        className: 'align-middle text-middle text-center',
        ngTemplateRef: {
          ref: this.deleteButtonCell,
        },
      },
    ];
  }
}
