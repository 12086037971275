// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { Component, ElementRef, HostListener, Input, ViewChild, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Component({
  selector: 'app-file-upload-control',
  templateUrl: './file-upload-control.component.html',
  styleUrls: ['./file-upload-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: FileUploadControlComponent,
      multi: true,
    },
  ],
})
export class FileUploadControlComponent implements ControlValueAccessor, Validator {
  /**
   * Can we select multiple files? By default can only select a single file.
   */
  @Input()
  multiple = false;

  @ViewChild('fileInput')
  fileInput: ElementRef<HTMLInputElement>;

  protected disabled = false;

  protected fileNames: string;

  protected placeholder = 'Click here to select a file';

  protected files: File[];

  @HostListener('change', ['$event.target.files'])
  protected emitFiles(fileList: FileList) {
    if (!fileList || !fileList.length) {
      return;
    }
    this.setValue([...fileList]);
    this.onChange(this.files);
  }

  onChange = (_: File | File[]) => {};

  /**
   * Write `files` value changed outside of this component (in the model) into this component (the view).
   *
   */
  writeValue(files: File[]): void {
    if (files != null) {
      // TODO: ATM only support writing null/undefined changes into this component. Need to update the HTML <input type="file">
      // "value" property directly too for non-null values; which is IMHO difficult/not possible?
      // Maybe can just write the changed file name into the <input> value and it's good enough?
      console.warn(
        'WARN FileUploadControl: writeValue() only supports null/undefined values. Non-null values are indeterminate behaviour.',
      );
    }
    this.setValue(files);
  }

  registerOnChange(onChange: (files: File | File[]) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(fn: unknown): void {
    //Not implemented
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value || control.value === '') {
      return { required: true };
    }
    return null;
  }

  protected clear() {
    this.setValue(null);
    this.onChange(this.files);
  }

  protected setValue(files: File[]) {
    this.files = !files ? null : this.multiple ? [...files] : [files.slice(0)[0]];
    this.fileNames = (this.files ?? []).reduce((s, curr) => (!s ? curr?.name : `${s}, ${curr.name}`), null as string);

    // Clear/Delete selected files. Need to explicitly make sure the <input> is cleared too. The <input> value is set by the browser and not by our typescript.
    // During file delete, it's a pure typescript action. Thus the browser does not clear the <input> value.
    if (!this.files?.length) {
      this.fileInput.nativeElement.value = '';
    }
  }
}
