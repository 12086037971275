import { Component, input } from '@angular/core';
import { ERiskRating } from 'src/app/company-record/company-record.model';

@Component({
  selector: 'app-indicator-tag',
  templateUrl: './indicator-tag.component.html',
  styleUrls: ['./indicator-tag.component.scss'],
})
export class IndicatorTagComponent {
  rating = input<ERiskRating | undefined>();
  size = input<'small' | 'large'>('small');

  protected get text() {
    const rating = this.rating();
    if (rating === undefined) return '-';
    return riskRatingLabels[ERiskRating[rating]] ?? '-';
  }

  protected get ratingClass(): string {
    const rating = this.rating();
    if (!rating) return 'tag-none';
    return `tag-${rating.toLowerCase()}`;
  }
}

// TODO: Duplicated from company records
// Make this single source of truth *somewhere*
export const riskRatingLabels = {
  [ERiskRating.LOW]: 'Low',
  [ERiskRating.MODERATE_LOW]: 'Moderate Low',
  [ERiskRating.MODERATE]: 'Moderate',
  [ERiskRating.MODERATE_HIGH]: 'Moderate High',
  [ERiskRating.HIGH]: 'High',
};
