<!--
  Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
  Unauthorized copying of this file, via any medium is strictly prohibited.
  Proprietary and confidential.
-->

<button class="btn btn-link" (click)="show()">
  <mat-icon aria-hidden="false" aria-label="Question Mark" fontIcon="help_outline" />
  <span *ngIf="showText">{{ title }}</span>
</button>

<div #helpModal class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content block">
      <h5 *ngIf="title">{{ title }}</h5>
      <div class="help-text my-4">
        <ng-content select=".body-contents"></ng-content>
      </div>
    </div>
  </div>
</div>
