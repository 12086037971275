<!--
 Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited.
 Proprietary and confidential.
-->

<div class="toggle-switch border">
  <ng-container *ngFor="let option of options; let i = index">
    <input id="toggle-switch-{{ id }}-{{ option }}" name="toggle-switch-{{ id }}" type="radio" [checked]="i === selectedIx ? 'checked' : ''" />
    <label for="toggle-switch-{{ id }}-{{ option }}" class="t-smaller text-center" (click)="change(i)">{{ option }}</label>
  </ng-container>
</div>
