// @ts-strict-ignore
import { Component, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, Data, Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, filter } from 'rxjs';

interface Breadcrumb {
  label: string;
  url: string;
}
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss'],
})
export class BreadCrumbComponent implements OnInit {
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  readonly routerEvents$: Observable<Event>;

  constructor(private router: Router) {
    this.routerEvents$ = this.router.events.pipe(
      takeUntilDestroyed(),
      filter(event => event instanceof NavigationEnd),
    );
  }

  ngOnInit(): void {
    this.buildBreadcrumbs();

    this.routerEvents$.subscribe(() => this.buildBreadcrumbs());
  }

  private buildBreadcrumbs() {
    const root = this.router.routerState.snapshot.root;
    const breadcrumbs: Breadcrumb[] = [];
    this.addBreadcrumb(root, [], breadcrumbs);
    this._breadcrumbs$.next(breadcrumbs);
  }

  private addBreadcrumb(route: ActivatedRouteSnapshot, parentUrl: string[], breadcrumbs: Breadcrumb[]) {
    if (route) {
      const routeUrl = parentUrl.concat(route.url.map(url => url.path));
      if (route.data.breadcrumb) {
        const breadcrumb = {
          label: this.getLabel(route.data),
          url: '/' + routeUrl.join('/'),
        };
        breadcrumbs.push(breadcrumb);
      }

      this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
    }
  }

  private getLabel(data: Data) {
    return typeof data.breadcrumb === 'function' ? data.breadcrumb(data) : data.breadcrumb;
  }
}
