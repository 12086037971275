<!--
  Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
  Unauthorized copying of this file, via any medium is strictly prohibited.
  Proprietary and confidential.
-->

<div #myModal class="modal fade" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content block">
      <h5>{{ title || 'Modal header ?' }}</h5>
      <div class="my-4">
        <ng-content select=".body-contents"></ng-content>
      </div>
      <div class="modal-footer">
        <button *ngIf="cancellable" type="button" class="btn btn-outline" (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="ok()">OK</button>
      </div>
    </div>
  </div>
</div>
