<!--
 Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited.
 Proprietary and confidential.
-->

<section *ngFor="let option of options?.concat(customOptions) ?? []; let i = index" class="form-check">
  <label class="form-check-label">
    <input type="checkbox" class="form-check-input" name="option{{ i }}" (change)="onChecked(option, $event.target.checked)" [value]="option" [checked]="selected?.includes(option)" [disabled]="disabled" #option{{i}} id="option{{ i }}" />
    {{ option }}
  </label>
</section>
<input *ngIf="allowAdd" type="text" class="form-control" placeholder="Add option" (keydown.enter)="onTextboxEnter($event)" [disabled]="disabled" />
