// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-nav-dot-tab',
  templateUrl: './nav-dot-tab.component.html',
  styleUrls: ['./nav-dot-tab.component.scss'],
})
export class NavDotTabComponent {
  @Input()
  key: string;

  @Input()
  label: string;

  @Input()
  selected = false;

  @Output()
  tabSelected: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  onSelected() {
    this.tabSelected.emit(this.key);
  }
}
