<span class="text">
  <span class="state-done">
    <ng-content select=".when-done" />
  </span>
  <span class="state-doing">
    <ng-content select=".when-doing" />
  </span>
  <span class="state-normal">
    <ng-content />
  </span>
</span>
<span class="arrow">
  @if (state() === 'done') {
    <mat-icon svgIcon="tick" />
  } @else if (state() === 'doing') {
    <mat-icon svgIcon="hourglass" />
  } @else {
    <mat-icon svgIcon="arrow-right" />
  }
</span>
