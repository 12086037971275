// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { AfterViewChecked, Component, HostBinding, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-collapsable-panel',
  templateUrl: './collapsable-panel.component.html',
  styleUrls: ['./collapsable-panel.component.scss'],
})
export class CollapsablePanelComponent implements OnInit, AfterViewChecked {
  @Input()
  name: string;

  @HostBinding('style.--colour')
  @Input()
  colour: string;

  id: string;

  collapsed: boolean;

  readonly routerEvents$: Observable<Event>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.routerEvents$ = this.router.events.pipe(
      takeUntilDestroyed(),
      filter(e => e instanceof NavigationEnd),
    );
  }

  ngOnInit(): void {
    // Generate a unique id that won't conflict with other elements
    this.id = this.randomString();

    this.determineCollapse();

    // Check whether collapse is necessary each time a navigation event occurs
    this.routerEvents$.subscribe(() => {
      this.determineCollapse();
      this.showOrHide();
    });
  }

  ngAfterViewChecked(): void {
    this.showOrHide();
  }

  determineCollapse(): void {
    // Collapse when this route is not last in the path
    this.collapsed = this.route.children[0]?.snapshot.url.length > 0;
  }

  showOrHide(): void {
    if (this.collapsed) {
      $(`#collapsable-${this.id}`).collapse('hide');
    } else {
      $(`#collapsable-${this.id}`).collapse('show');
    }
  }

  randomString(): string {
    return (Math.random() + 1).toString(36).substring(2, 7);
  }
}
