// @ts-strict-ignore
// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Injectable } from '@angular/core';
import { Observable, combineLatest, first, map, of, shareReplay, switchMap } from 'rxjs';
import { Country } from 'src/app/country/country.model';
import { CountryService } from 'src/app/country/country.service';
import { Industry, Sector, SectorWithWeight } from 'src/app/industry/industry.model';
import { IndustryService } from 'src/app/industry/industry.service';
import { parseAquilaUri } from 'src/app/sector/sector.types';

@Injectable({
  providedIn: 'root',
})
export class SectorListService {
  // Wrapped in outer pipes so that the services aren't hit until subscribed to.
  readonly countries$ = of(null).pipe(
    switchMap(() => this.countryService.getAll$()),
    shareReplay(1),
  );
  readonly industries$ = of(null).pipe(
    switchMap(() => this.industryService.getAll$()),
    shareReplay(1),
  );

  constructor(
    private readonly countryService: CountryService,
    private readonly industryService: IndustryService,
  ) {}

  fromUri$(uri: string): Observable<Sector>;
  fromUri$(uri: string, weight: number): Observable<SectorWithWeight>;
  fromUri$(uri: string, weight?: number) {
    const parsedUri = parseAquilaUri(uri);
    if (parsedUri.block?.id !== 'IDI' || parsedUri.block?.version !== 1) {
      throw new Error(`Can't get sector: unsupported block ${parsedUri.block}`);
    }
    if (!parsedUri.item || !parsedUri.country) {
      throw new Error("Can't create sector from URI ");
    }
    const sector$ = combineLatest([this.countries$, this.industries$]).pipe(
      map(([countries, industries]) => ({
        country: countries.find(c => c.short === parsedUri.country.short),
        industry: industries.find(i => i.id === parsedUri.item.id),
      })),
      first(),
    );
    if (weight === undefined) {
      return sector$ satisfies Observable<Sector>;
    }
    return sector$.pipe(map(sector => ({ ...sector, weight }))) satisfies Observable<SectorWithWeight>;
  }

  /** @deprecated use {@link countries$} instead. */
  getCountries$(): Observable<Country[]> {
    return this.countries$;
  }

  /** @deprecated use {@link industries$} instead. */
  getIndustries$(): Observable<Industry[]> {
    return this.industries$;
  }
}
