import { BooleanFlag, PermanentFlags, TemporaryFlags } from './feature-flags.types';

/**
 * Feature flags are defined here so that they are evaluated consistently
 * throughout the platform, even after the flag is archived in LD. Process:
 *
 * 1. Add a flag here
 * 2. Add it to [the LD dashboard](https://app.launchdarkly.com)
 *
 * @see [README.md](README.md)
 */
export const featureFlags = {
  'release-top-nav': new BooleanFlag(),
  'release-saq-engagement-screen': new BooleanFlag(),
  'release-reports-pdf-download': new BooleanFlag(),
  'allow-component-zoo': new BooleanFlag(),
  'allow-delete-records': new BooleanFlag(),
} as const satisfies TemporaryFlags & PermanentFlags;

export type FFKey = keyof typeof featureFlags;
