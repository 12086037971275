<!--
 Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited.
 Proprietary and confidential.
-->
<div *ngIf="horizontal">
  <div class="d-flex flex-row justify-content-start">
    <img src="/assets/images/logos/fs-new/logo-icon.svg" [width]="iconSize" [height]="iconSize" />
    <div class="ml-3">
      <h3>{{ selectedTitle }}</h3>
      <p>{{ selectedSubtitle }}...</p>
    </div>
  </div>
</div>

<div *ngIf="!horizontal">
  <div class="d-flex flex-column justify-content-center">
    <img class="mb-4" src="/assets/images/logos/fs-new/logo-icon.svg" [height]="iconSize" />
    <h3>{{ selectedTitle }}</h3>
    <p>{{ selectedSubtitle }}...</p>
  </div>
</div>
