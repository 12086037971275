<!--
 Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited.
 Proprietary and confidential.
-->

<mat-form-field>
  <input #input type="text" [placeholder]="placeholder" matInput [formControl]="formControl" [matAutocomplete]="autocomplete" (keydown)="onInputKeydown()" (keydown.enter)="onInputEnter($event, $event.target.value)" (blur)="onInputBlur($event, $event.target.value)" />
  <mat-autocomplete #autocomplete="matAutocomplete" (optionSelected)="onChange($event.option.value)" [displayWith]="getDisplay">
    <mat-option *ngFor="let o of filteredOptions$ | async" tabindex="0" [value]="o.value">{{ o.display }}</mat-option>
  </mat-autocomplete>
</mat-form-field>
