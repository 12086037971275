// @ts-strict-ignore
// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
  selector: 'app-multiple-options',
  templateUrl: './multiple-options.component.html',
  styleUrls: ['./multiple-options.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleOptionsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: MultipleOptionsComponent,
      multi: true,
    },
  ],
})
export class MultipleOptionsComponent<T extends string> implements ControlValueAccessor, Validator {
  @Input() options: T[];
  @Input() allowAdd = false;

  protected disabled = false;
  protected selected: T[] = null;
  protected customOptions: T[] = [];

  writeValue(value: T[]): void {
    this.selected = value;
    this.customOptions = this.selected?.filter(s => !this.options.includes(s)) ?? [];
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  validate(): ValidationErrors {
    return null;
  }

  registerOnChange(onChange: typeof this.onChangeCallback) {
    this.onChangeCallback = onChange;
  }
  registerOnTouched(onTouched: typeof this.onTouched) {
    this.onTouched = onTouched;
  }

  protected onChangeCallback = (_: T[]) => {};
  protected onTouched = () => {};

  protected onChecked(value: T, checked: boolean) {
    if (checked) {
      this.selected = [...(this.selected ?? []), value];
    } else if (this.selected.length > 1) {
      this.selected = this.selected.filter(o => o !== value);
    } else {
      this.selected = null;
    }
    this.onTouched();
    this.onChangeCallback(this.selected);
  }

  protected onTextboxEnter(e: KeyboardEvent) {
    e.preventDefault();
    const textbox = e.target as HTMLInputElement;
    const value = textbox.value as T;
    this.customOptions = [...this.customOptions, value];
    this.onChecked(value, true);
    textbox.value = null;
  }
}
