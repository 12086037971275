import { LDClient, LDContext, LDFlagSet, LDOptions, initialize } from 'launchdarkly-js-client-sdk';
import { deepEqual } from '../shared/util';

/**
 * A thin wrapper around {@link LDClient} with a more convenient stateful
 * interface. This is needed because an instance of LDClient can't be created
 * without providing a context, but then when the context changes you have to
 * call a different method to update it — which makes working with it with
 * observables quite tricky. In contrast, this class:
 * - Can be created without a context
 * - Has far simpler start/stop state transitions.
 */
export class LDClientWrapper {
  ldclient: LDClient | null = null;

  constructor(
    /**
     * The client-side environment key. This is similar to the SDK key but it is
     * not the same, because the latter is secret.
     * */
    readonly envKey: string,
    readonly options: LDOptions,
    readonly initTimeout: number,
  ) {}

  /**
   * Ensure the client is running, and (re)-identify the user.
   *
   * Optionally, the initial state of the feature flags can be set with the
   * `bootstrap` parameter. If bootstrap values are provided, this method will
   * never block. However, the bootstrap values will only be used if the
   * internal LDClient has not yet been initialized.
   *
   * @param context the user, device, or client (customer) that will be
   * targetted for flag values
   * @param bootstrap the initial values to use for the flags. If not provided
   * and the LDClient has not yet been initialized, this method may block like
   * {@link LDClient.waitForInitialization}.
   *
   * @see {@link initialize LD → initialize}
   * @see {@link LDClient.identify}
   */
  async start(context: LDContext, bootstrap?: LDFlagSet) {
    if (!this.ldclient) {
      await this.startNewClient(context, bootstrap);
    } else if (!deepEqual(this.ldclient.getContext(), context)) {
      await this.reuseExistingClientWithNewContext(this.ldclient, context);
    }
    return this;
  }

  /**
   * Close the connection with the LD server. It's important (but not strictly
   * required) to call this when the user stops using the app.
   *
   * @see {@link LDClient.close}
   */
  async stop() {
    const ldclient = this.ldclient;
    this.ldclient = null;
    await ldclient?.close();
    return this;
  }

  private async reuseExistingClientWithNewContext(ldclient: LDClient, context: LDContext) {
    console.debug(`LD: reusing existing client with context ${context.key}`);
    await ldclient.identify(context);
  }

  private async startNewClient(context: LDContext, bootstrap?: LDFlagSet) {
    console.debug(`LD: initializing new client with context ${context.key}`);
    const options = { ...this.options, bootstrap };
    this.ldclient = initialize(this.envKey, context, options);
    await this.ldclient.waitForInitialization(this.initTimeout);
  }
}
