import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AssessmentItemData } from '../../assessment/assessment-item.model';
import { EntityType, EMode } from '../../assessment/assessment.model';
import { Client } from '../../client/client.model';

export interface SaqRequestModalModel {
  item: AssessmentItemData;
  mode: keyof typeof EMode;
  client: Client;
  itemType: EntityType;
}

export interface SaqRequestSentModel {
  item: AssessmentItemData;
}

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireRequestService {
  saqRequestModalSubject$ = new Subject<SaqRequestModalModel>();
  saqRequestModal$ = this.saqRequestModalSubject$.asObservable();

  saqRequestSentSubject$ = new Subject<SaqRequestSentModel>();
  saqRequestSent$ = this.saqRequestSentSubject$.asObservable();

  constructor() {}

  showSAQRequestModal(saqRequestModalModel: SaqRequestModalModel) {
    this.saqRequestModalSubject$.next(saqRequestModalModel);
  }

  saqRequestSent(saqRequestSentModel: SaqRequestSentModel) {
    this.saqRequestSentSubject$.next(saqRequestSentModel);
  }
}
