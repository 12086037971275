// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { Component } from '@angular/core';

@Component({
  selector: 'app-no-client',
  templateUrl: './no-client.component.html',
  styleUrls: ['./no-client.component.scss'],
})
export class NoClientComponent {
  constructor() {}
}
