// @ts-strict-ignore
import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { first, tap } from 'rxjs/operators';
import { Industry } from 'src/app/industry/industry.model';
import { IndustryService } from 'src/app/industry/industry.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[descriptionTooltip]',
})
export class IndustryDescriptionTooltipDirective implements OnInit {
  @Input('descriptionTooltip')
  name: string;

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
    private readonly industryService: IndustryService,
  ) {}

  ngOnInit() {
    this.industryService
      .getAll$()
      .pipe(
        first(),
        tap((industries: Industry[]) => {
          const description = industries.find(industry => industry.name === this.name)?.description;

          if (description) {
            this.renderer.setAttribute(this.el.nativeElement, 'title', description);
            this.renderer.setAttribute(this.el.nativeElement, 'data-toggle', 'tooltip');
            this.renderer.setAttribute(this.el.nativeElement, 'data-placement', 'top');
            $(this.el.nativeElement).tooltip({ boundary: 'window', delay: { show: 500, hide: 100 } });
          }
        }),
      )
      .subscribe();
  }
}
