// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
})
export class ToggleSwitchComponent implements OnInit {
  @Input()
  options: string[];

  @Input()
  selectedIx = 0;

  @Output()
  changed: EventEmitter<number> = new EventEmitter();

  id: string;

  ngOnInit(): void {
    // Random id to reference this switch, so that others switches can exist together
    this.id = (Math.random() * 1000).toFixed();
  }

  change(index: number) {
    this.selectedIx = index;
    this.changed.emit(this.selectedIx);
  }
}
