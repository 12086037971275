// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Component, ElementRef, HostBinding, OnInit, booleanAttribute, effect, inject, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'button[fsn-squishy-button],a[fsn-squishy-button]',
  templateUrl: './squishy-button.component.html',
  styleUrls: ['./squishy-button.component.scss'],
  standalone: true,
  imports: [MatIconModule],
})
export class SquishyButtonComponent implements OnInit {
  readonly color = input<'primary' | 'accent' | 'warn'>('primary');
  readonly size = input<'medium' | 'large'>('medium');
  readonly disabled = input(false, { transform: booleanAttribute });
  readonly state = input<'normal' | 'doing' | 'done'>('normal');

  private readonly host = inject<ElementRef<HTMLAnchorElement | HTMLButtonElement>>(ElementRef);

  @HostBinding('class')
  protected get cls() {
    return {
      'app-primary': this.color() === 'primary',
      'app-accent': this.color() === 'accent',
      'app-warn': this.color() === 'warn',
      'app-disabled': this.disabled(),
      'button-medium': this.size() === 'medium',
      'button-large': this.size() === 'large',
      'app-is-done': this.state() === 'done',
      'app-is-doing': this.state() === 'doing',
      'app-is-normal': this.state() === 'normal',
    };
  }

  protected readonly onButtonDisabled = effect(() => {
    const disabled = this.disabled();
    const host = this.host.nativeElement;
    if ('disabled' in host) host.disabled = disabled;
  });

  ngOnInit() {
    const host = this.host.nativeElement;
    if (host.tagName === 'A') {
      host.addEventListener('pointerdown', event => this.doNothingWhenDisabled(event), { capture: true });
      host.addEventListener('click', event => this.doNothingWhenDisabled(event), { capture: true });
    }
  }

  protected doNothingWhenDisabled(event: Event) {
    if (this.disabled()) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}

export type SquishyButtonSize = ReturnType<SquishyButtonComponent['size']>;
export type SquishyButtonState = ReturnType<SquishyButtonComponent['state']>;
