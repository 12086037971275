/* eslint-disable @typescript-eslint/member-ordering */
import { DestroyRef, Injectable, effect, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { LDClient, LDContext, LDFlagSet, LDOptions, basicLogger } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, Subject, combineLatest, fromEvent, map, startWith } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Environment } from 'src/environments/environment.types';
import { CurrentUser, UserService } from '../user/user.service';
import { LDClientWrapper } from './launchdarkly.model';

// TODO: configure these in a provider and inject them into the service.
const initTimeout = 5;

export interface Identity {
  /** The current user, device, or client to identify as. */
  readonly context: LDContext;
  /** The flag values to use initially. */
  readonly bootstrap?: LDFlagSet;
}

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService {
  private readonly destroyRef = inject(DestroyRef);
  private readonly userService = inject(UserService);

  private readonly lifecycle$ = new BehaviorSubject<'start' | 'stop'>('start');
  private readonly identity$ = new Subject<Identity>();
  private readonly event$ = combineLatest({ lifecycle: this.lifecycle$, identity: this.identity$ }).pipe(
    takeUntilDestroyed(this.destroyRef),
  );

  private readonly _ldclient = signal<LDClient | null>(null);
  readonly ldclient$ = toObservable(this._ldclient);

  /** This service is a state machine. Refer to [README.md](README.md). */
  private readonly _eventLoop = effect(() => {
    const config = getLdConfig(environment.launchDarkly);
    if (!config) return;
    const wrappedLdClient = new LDClientWrapper(config.envKey, config.options, initTimeout);
    this.event$.subscribe(async ({ lifecycle, identity }) => {
      // Await each state change so that the changes are atomic.
      if (lifecycle === 'start') await wrappedLdClient.start(identity.context, identity.bootstrap);
      else if (lifecycle === 'stop') await wrappedLdClient.stop();
      this._ldclient.set(wrappedLdClient.ldclient);
    });
    // Don't unsubscribe: we want to get every last message, and event$ will self-destruct.
  });

  private readonly _lifecycle = effect(cleanup => {
    this.lifecycle$.next('start');
    cleanup(() => this.lifecycle$.next('stop'));
  });

  private readonly _onPageVisibilityChange = effect(cleanup => {
    const sub = pageVisibility$()
      .pipe(map(visibility => (visibility === 'visible' ? 'start' : 'stop')))
      .subscribe(state => this.lifecycle$.next(state));
    cleanup(() => sub.unsubscribe());
  });

  private readonly _onUserChange = effect(cleanup => {
    const sub = this.userService.currentUser$
      .pipe(map(user => ({ context: contextFromUser(user), bootstrap: user.featureFlags ?? undefined })))
      .subscribe(identity => this.identity$.next(identity));
    cleanup(() => sub.unsubscribe());
  });
}

const contextFromUser = (user: CurrentUser): LDContext => ({
  // IMPORTANT: the key must be the same as the one used by LDClient in the backend. It should also not contain any PII.
  key: `user-id-${user.id}`,
  kind: 'user',
  anonymous: false,
  email: user.email,
});

const pageVisibility$ = () =>
  fromEvent(document, 'visibilitychange').pipe(
    map(() => document.visibilityState),
    startWith('visible' as const),
  );

const getLdConfig = (settings: Environment['launchDarkly']): { envKey: string; options: LDOptions } | null => {
  if (!settings) return null;

  const options: LDOptions = {
    streaming: settings.streaming,
    logger: basicLogger({ level: settings.logLevel ?? 'warn' }),
  };

  if (settings.clientSideId !== undefined) {
    const { clientSideId } = settings;
    return { envKey: clientSideId, options };
  } else {
    const { projectKey, url } = settings;
    return { envKey: projectKey, options: { ...options, baseUrl: url, eventsUrl: url, streamUrl: url } };
  }
};
