// @ts-strict-ignore
// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab-component.html',
  styleUrls: ['./tab-component.scss'],
})
export class TabComponent {
  @ContentChild('content', { static: true }) content: TemplateRef<HTMLElement>;

  @Input()
  name: string;

  @Input()
  preload? = true;

  active = false;

  loaded = false;
}
