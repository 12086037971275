// @ts-strict-ignore
// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { AfterContentInit, ContentChildren, Directive, ElementRef, OnDestroy, QueryList } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { TabStripComponent } from './tab-strip-component';
import { TabComponent } from './tab/tab-component';

@Directive({
  selector: 'app-tab-strip[appScrolling]',
})
export class TabStripAnchorScrollingDirective implements AfterContentInit, OnDestroy {
  @ContentChildren(TabComponent, { read: ElementRef }) tabs: QueryList<ElementRef>;

  private tabIdsAndComponents: { id: string; tab: TabComponent }[];

  private fragmentSubscription: Subscription;
  private navigationSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private tabStrip: TabStripComponent,
    private element: ElementRef,
  ) {}

  ngAfterContentInit(): void {
    const tabs = this.tabStrip.tabs.toArray();
    this.tabIdsAndComponents = this.tabs.map((t, i) => ({
      id: t.nativeElement.getAttribute('scrollId'),
      tab: tabs[i],
    }));

    this.fragmentSubscription = this.route.fragment.pipe().subscribe(currentFragment => {
      this.changeTab(currentFragment);
    });

    this.navigationSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentFragment = this.location.path(true).split('#')[1];
        this.changeTab(currentFragment);
      }
    });
  }

  ngOnDestroy(): void {
    this.fragmentSubscription.unsubscribe();
    this.navigationSubscription.unsubscribe();
  }

  private changeTab = (id: string) => {
    const newTab = this.tabIdsAndComponents.find(t => t.id === id);
    if (newTab) {
      this.tabStrip.selectTab(newTab.tab);
      this.element.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
}
