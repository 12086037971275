<!--
 Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited.
 Proprietary and confidential.
-->

<footer class="container py-5">
  <section class="border-top pt-5 d-flex flex-lg-row flex-column justify-content-between align-items-start">
    <img class="mx-auto mx-md-0 mb-4 d-block" src="/assets/images/logos/fs-new/logo-full.svg" width="300" />

    @if (client()?.id === 262) {
      <article>
        <h4 class="heading">In Partnership With</h4>
        <div class="d-flex flex-lg-row flex-column">
          <img class="mx-auto mx-md-0 mb-4 d-block" src="/assets/images/logos/lseg/bursa.svg" width="125" />
          <img class="mx-auto mx-md-0 mb-4 d-block" src="/assets/images/logos/lseg/ssm.jpg" width="125" />
        </div>
      </article>
    }

    <article>
      <h4 class="heading">Responsible Business</h4>
      <div class="d-flex flex-lg-row flex-column">
        <img src="/assets/images/iso-27001.png" width="200" />
      </div>
    </article>
  </section>

  <section class="mt-5 d-flex">
    <small>© Copyright 2024</small>
    <small class="ml-auto">v {{ version }}</small>
    <a class="ml-5" href="https://fairsupply.com.au/privacy-policy/"><small>Privacy Policy</small></a>
  </section>
</footer>
