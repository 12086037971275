// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MenuData } from '../nav-dot-tab-strip.component';

@Component({
  selector: 'app-nav-dot-menu',
  templateUrl: './nav-dot-menu.component.html',
  styleUrls: ['./nav-dot-menu.component.scss'],
})
export class NavDotMenuComponent implements OnChanges {
  @Input()
  menu: MenuData[];

  @Output()
  menuSelected: EventEmitter<string> = new EventEmitter<string>();

  selectedItem: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedItem = (changes.menu.currentValue as MenuData[]).find(tab => tab.selected).value;
  }

  onMenuSelected(event: MatSelectChange) {
    const option = event.value;
    this.menuSelected.emit(option);
  }
}
