<!--
 Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited.
 Proprietary and confidential.
-->

<div id="collapsable-{{ id }}" class="collapse hide">
  <ng-content></ng-content>
</div>
<div *ngIf="collapsed" class="mb-5">
  <button class="btn btn-link text-white w-100" [routerLink]="['.']" queryParamsHandling="preserve">{{ name }}</button>
</div>
