// @ts-strict-ignore
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tab-strip-header',
  templateUrl: './tab-strip-header.component.html',
  styles: [':host { display: block }', ':host.centered-items .nav-tabs { justify-content: center }'],
})
export class TabStripHeaderComponent<T> implements OnInit {
  @Input() tabs: T[];
  @Input() labelProp: keyof T;
  @Input() enabledProp: keyof T;

  @Input() selectedTab: T;
  @Output() selectedTabChanged = new EventEmitter<T>();

  ngOnInit() {
    if (!this.selectedTab) {
      this.selectedTab = this.tabs.filter(t => !this.enabledProp || t[this.enabledProp])[0];
    }
  }

  selectTab(t: T) {
    this.selectedTab = t;
    this.selectedTabChanged.emit(t);
  }
}
