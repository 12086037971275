// @ts-strict-ignore
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  booleanAttribute,
} from '@angular/core';

type Appearance = 'outlined' | 'filled';

@Component({
  selector: 'fsn-pill-tabs',
  templateUrl: './pill-tabs.component.html',
  styleUrls: ['./pill-tabs.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class PillTabsComponent implements OnChanges {
  @HostBinding('class.fs-next') private readonly fsNext = true;
  @Input() tabs: string[];

  @Input() selectedTab: string;
  @Output() selectedTabChange = new EventEmitter<string>();
  @Input() selectedIndex: number;
  @Output() selectedIndexChange = new EventEmitter<number>();

  @Input() appearance: Appearance = 'filled';
  @Input() color: 'primary' | 'accent' | 'warn';
  @Input({ transform: booleanAttribute }) small = false;

  @HostBinding('class') get cls() {
    return {
      [`app-${this.appearance}`]: true,
      [`app-${this.color}`]: true,
      ['small']: this.small,
    };
  }

  // TODO: Use tablist, tab, and tabpanel roles as described in https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tab_role#best_practices
  @HostBinding('attr.role') role = 'listbox';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedIndex) {
      setTimeout(() => {
        this.selectedIndex = changes.selectedIndex.currentValue;
        this.selectedTab = this.tabs[changes.selectedIndex.currentValue];
        this.selectedIndexChange.emit(this.selectedIndex);
      });
    }
    if (changes.selectedTab) {
      setTimeout(() => {
        this.selectedIndex = this.tabs.findIndex(tab => tab === changes.selectedTab.currentValue);
        this.selectedTab = changes.selectedTab.currentValue;
        this.selectedTabChange.emit(this.selectedTab);
      });
    }
  }

  protected selectTab(selectedTab: string) {
    if (this.selectedTab !== selectedTab) {
      this.selectedTab = selectedTab;
    }
    this.selectedTabChange.emit(selectedTab);
    this.selectedIndexChange.emit(this.tabs.indexOf(selectedTab));
  }
}
