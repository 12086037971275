// @ts-strict-ignore
// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Directive, ElementRef, HostListener, Input, OnChanges, OnInit } from '@angular/core';
import { getRandom } from 'src/app/shared/util';

@Directive({
  selector: 'input[appTypewriter]',
})
export class TypeWriterInputDirective implements OnInit, OnChanges {
  @Input()
  placeholders: string[];

  animating = false;

  private stop = false;

  private defaultPlaceholder: string;

  private delayAfterAnimation = 3000;

  constructor(private element: ElementRef) {}

  @HostListener('blur')
  protected onBlur() {
    if (!this.element.nativeElement.value) {
      this.animatePlaceholder();
    }
  }

  @HostListener('focus')
  protected onFocus() {
    this.animating = false;
    this.updatePlaceholder(this.defaultPlaceholder);
  }

  ngOnInit(): void {
    this.defaultPlaceholder = this.element.nativeElement.placeholder;
  }

  ngOnChanges(): void {
    if (this.placeholders) {
      this.animatePlaceholder();
    }
  }

  private updatePlaceholder(placeholder: string) {
    this.element.nativeElement.placeholder = placeholder;
  }

  private getRandomPlaceholderValue(lastPlaceholder?: string) {
    return getRandom(this.placeholders.filter(p => p !== lastPlaceholder));
  }

  private animatePlaceholder() {
    this.animating = true;
    this.animateValue(this.getRandomPlaceholderValue());
  }

  private animateValue = async (placeholder: string) => await this.animateLetters([], placeholder.split(''));

  private animateLetters = async (currentLetters: string[], remainingLetters: string[]) => {
    if (!remainingLetters.length) {
      return await this.animationEnd(currentLetters.join(''));
    }
    currentLetters.push(remainingLetters.shift());

    await this.delay(this.getRandomBetween(50, 150));
    if (!this.stop && this.animating) {
      this.updatePlaceholder(currentLetters.join('') + '_');
      this.animateLetters(currentLetters, remainingLetters);
    }
  };

  private animationEnd = async (lastPlaceholder: string) => {
    await this.delay(this.delayAfterAnimation);
    this.animateValue(this.getRandomPlaceholderValue(lastPlaceholder));
  };

  private getRandomBetween = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);

  private delay = (n: number) => new Promise<void>(r => setTimeout(r, n));
}
