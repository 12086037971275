// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { Component, Input } from '@angular/core';
import { RiskRating } from 'src/app/company-record/company-record.model';

@Component({
  selector: 'app-risk-indicator',
  templateUrl: './risk-indicator.component.html',
  styleUrls: ['./risk-indicator.component.scss'],
})
export class RiskIndicatorComponent {
  @Input() rating: 'LOW' | ' MODERATE_LOW' | 'MODERATE' | 'MODERATE_HIGH' | 'HIGH';

  @Input()
  showLabel = true;

  protected readonly RISK_RATING = RiskRating;
}
