<!--
 Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited.
 Proprietary and confidential.
-->

<form (ngSubmit)="upload()" #form="ngForm">
  <div class="form-group">
    <div class="file-upload-wrapper" [class.uploading]="uploading" [attr.data-text]="message">
      <input type="file" [(ngModel)]="filePath" class="form-control" [class.uploading]="uploading" name="file" [disabled]="uploading" [multiple]="multiple" [accept]="supportedFileTypes" />
      <div *ngIf="!hideButton" class="action">
        <button type="submit" class="btn btn-primary" [disabled]="uploading || files.length === 0">{{ uploading ? 'Uploading...' : 'Upload' }}</button>
      </div>
    </div>
    <p class="t-smaller mt-2"><em>Supported File Types: .xlsx, .csv, .pdf, .jpg, .jpeg, .png and .txt</em></p>
    <div *ngIf="uploading" class="progress">
      <div class="progress-bar" role="progressbar" [style]="'width:' + progress + '%'" [attr.aria-valuenow]="progress" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </div>
</form>
