// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Output,
  QueryList,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Subject, asapScheduler, delay, takeUntil } from 'rxjs';
import { DataboxComponent } from '../../databox/databox.component';
import { BigTabStripComponent } from '../big-tab-strip/big-tab-strip.component'; // eslint-disable-line @typescript-eslint/no-unused-vars -- Needed for docstring.

@Component({
  selector: 'fsn-big-tab',
  templateUrl: './big-tab.component.html',
  styleUrls: ['./big-tab.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule],
})
export class BigTabComponent implements AfterContentInit, OnDestroy {
  @HostBinding('class.fs-next') private readonly fsNext = true;
  private readonly destroyed$ = new Subject<void>();

  @ContentChildren(DataboxComponent) databoxes: QueryList<DataboxComponent>;

  @Input() name: string;
  @HostBinding('attr.disabled') @Input() disabled = false;
  @HostBinding('class.selected') selected = false;

  @HostBinding('attr.role') get role() {
    return 'tab';
  }
  @HostBinding('attr.aria-selected') get ariaSelected() {
    return this.selected;
  }
  @HostBinding('attr.aria-tabindex') get ariaTabindex() {
    return this.selected ? 0 : -1;
  }
  @HostBinding('attr.aria-disabled') get ariaDisabled() {
    return this.disabled;
  }

  @Input() comingSoon: boolean;

  /**
   * The user wants to select the tab. This may fail if the tab is disabled, but
   * we emit it anyway so they can be told why. The actual state change is
   * handled by the parent {@link BigTabStripComponent}, because it needs to
   * update the states of the other tabs too.
   */
  @Output() readonly tabSelectRequested = new EventEmitter<BigTabComponent>();

  /**
   * This tab has actually been selected. This can only happen if it's not
   * disabled. This may follow {@link tabSelectRequested}, but it also happens
   * programmatically, e.g. when the selected index is set on the parent.
   */
  @Output() readonly tabSelected = new EventEmitter<BigTabComponent>();

  index: number;
  span = 1;

  constructor() {}

  ngAfterContentInit() {
    this.databoxes.changes
      .pipe(takeUntil(this.destroyed$), delay(0, asapScheduler))
      .subscribe(databoxes => this.databoxesChanged(databoxes.toArray()));
    this.databoxesChanged(this.databoxes.toArray());
  }

  databoxesChanged(databoxes: DataboxComponent[]) {
    this.span = Math.max(databoxes.length, 1);
  }

  @HostListener('click', ['$event'])
  onClick() {
    this.tabSelectRequested.emit(this);
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
