// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss'],
})
export class HelpModalComponent {
  @Input()
  title: string;

  @Input()
  showText = false;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onShow?: EventEmitter<boolean> = new EventEmitter();

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onHide?: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('helpModal')
  modal: ElementRef;

  show() {
    // Move the modal element to the body so that its not affected by an parent element. eg. sticky parents or parents with a z-index greater than the modal's.
    // The move will only happen once
    $(this.modal.nativeElement).appendTo('body').modal('show');
    $(this.modal.nativeElement).on('shown.bs.modal', () => {
      if (this.onShow) {
        this.onShow.emit();
      }
    });

    $(this.modal.nativeElement).on('hidden.bs.modal', () => {
      if (this.onHide) {
        this.onHide.emit();
      }
    });
  }
}
