<!--
  Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
  Unauthorized copying of this file, via any medium is strictly prohibited.
  Proprietary and confidential.
-->

<div class="block">
  <app-tab-strip-header class="mb-4" [tabs]="tabs" labelProp="name" [selectedTab]="selectedTab" (selectedTabChanged)="selectTab($event)" />

  <ng-container *ngFor="let tab of tabs; let i = index">
    <ng-content />
  </ng-container>
</div>
