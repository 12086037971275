// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

const messageDefault = 'Click here to select a file';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  /**
   * Can we select multiple files? By default can only select a single file.
   */
  @Input()
  multiple: boolean;

  @Input()
  hideButton = false;

  @Output()
  event: EventEmitter<{ files: File[]; progress: (amount: number) => void; finish: () => void }> = new EventEmitter();

  @Output()
  fileSelected: EventEmitter<{ files: File[]; clear: () => void }> = new EventEmitter();

  @ViewChild('form')
  form: NgForm;

  files: File[];

  /**
   * The `<input type="file">` `value` attribute.
   * Contains a string that represents the path to the selected file(s).
   *
   * If no file is selected yet, the value is an empty string (`""`).
   * When the user selected multiple files, the value represents the first file in the list of files they selected.
   *
   * The other files can be identified using the `<input>`'s `HTMLInputElement.files` property.
   */
  filePath: string;

  uploading: boolean;

  progress: number;

  message = messageDefault;

  // Include extension and MIME type (https://html.spec.whatwg.org/multipage/input.html#attr-input-accept)
  protected supportedFileTypes = [
    '.xlsx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.csv',
    'text/csv',
    '.pdf',
    'application/pdf',
    '.jpg',
    '.jpeg',
    'image/jpeg',
    '.png',
    'image/png',
    '.txt',
    'text/plain',
  ];

  constructor() {
    this.multiple = false;
    this.files = [];
  }

  @HostListener('change', ['$event.target.files'])
  emitFiles(fileList: FileList) {
    if (!fileList || !fileList.length) {
      this.files = [];
      this.message = messageDefault;
      return;
    }

    this.files = this.multiple ? [...fileList] : [fileList.item(0)];
    this.message = this.files.map((v: File) => v.name).reduce((prev, curr) => `${prev}, ${curr}`);
    this.fileSelected.emit({
      files: this.files,
      clear: () => {
        this.clear();
      },
    });
  }

  ngOnInit(): void {
    this.clear();
  }

  upload() {
    this.uploading = true;

    this.event.emit({
      files: this.files,
      progress: (amount: number) => {
        this.progress = amount;
      },
      finish: () => {
        this.clear();
      },
    });
  }

  clear() {
    this.files = [];
    this.filePath = null;
    this.uploading = false;
    this.progress = 0;
    this.message = messageDefault;

    if (this.form) {
      this.form.resetForm();
    }
  }
}
