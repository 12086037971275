<!--
  Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
  Unauthorized copying of this file, via any medium is strictly prohibited.
  Proprietary and confidential.
-->
<div class="tab-headers border-bottom">
  <ul class="nav nav-tabs row-gap-2">
    <li class="nav-item" *ngFor="let tab of tabs">
      <button class="nav-link" [class.active]="tab === selectedTab" [disabled]="enabledProp && !tab[enabledProp]" (click)="selectTab(tab)">
        <h3>{{ tab[labelProp] }}</h3>
      </button>
    </li>
  </ul>
</div>
