// @ts-strict-ignore
// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Component, Input, OnInit } from '@angular/core';
import { getRandom } from 'src/app/shared/util';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  @Input()
  horizontal = false;

  @Input()
  iconSize = 80;

  selectedTitle: string;

  selectedSubtitle: string;

  private titles = [
    'Be right back',
    'Just one moment',
    'Hold tight',
    'Just a moment',
    'Almost done',
    'Preparing your data',
    'In progress',
    'Processing your request',
    'Fetching information',
    'Data is on its way',
    'Loading content',
    'Working on it',
    'Just a little longer',
    'Content is loading',
    'Collecting your data',
    'Almost ready',
    'Loading your data',
    'Preparing your results',
    'Your content is coming',
    'Working behind the scenes',
    'Fetching your content',
    'Work underway',
    'Request underway',
    'Processing data',
    'Almost finished',
  ];

  private subtitles = [
    'Your request is being processed',
    'Just getting your results',
    'Your results are loading',
    "We're getting things ready for you",
    "We're working on your request",
    'Your content is loading',
    'Your request is on its way',
    'Just a few more seconds',
    'Thanks for your patience',
    "We're putting the pieces together",
    "It's almost ready",
    'Thank you for waiting',
    'Your content will be here shortly',
    'Your data is loading',
    "We're almost there",
    'Your request is in progress',
    'Your wait will be worth it',
    'Your content is coming soon',
    'Sit back and relax',
    'Thanks for waiting',
    'Your content is about to load',
    "We're on it",
    'Just a few moments more',
    "Sit tight, we're almost there",
    'Thanks for waiting',
    'Your content is coming soon',
    "We're putting the finishing touches",
    'Just a little more time',
    'Your data is almost here',
  ];

  ngOnInit(): void {
    this.selectedTitle = getRandom(this.titles);
    this.selectedSubtitle = getRandom(this.subtitles);
  }
}
