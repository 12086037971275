// @ts-strict-ignore
// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AutocompleteComponent,
      multi: true,
    },
  ],
})
export class AutocompleteComponent<T> implements OnInit, AfterViewInit, ControlValueAccessor, Validator {
  @ViewChildren(MatOption<T>) matOptions: QueryList<MatOption<T>>;
  @Input() options: T[];
  @Input() displayProperty?: keyof T;
  @Input() placeholder = 'Select an option';

  protected filteredOptions$: Observable<WithDisplay<T>[]>;
  protected formControl = new FormControl<string | T>('');

  private value: T | string;
  private mappedOptions: WithDisplay<T>[];

  ngOnInit() {
    if (!this.options) {
      return console.error('AutocompleteComponent must have "options"');
    }
    const hasObjects = this.options.some(i => typeof i === 'object');

    if (hasObjects && !this.displayProperty) {
      return console.error(`AutocompleteComponent must have "displayProperty"`);
    }

    this.mappedOptions = this.options.map(o => ({ value: o, display: this.getDisplay(o) }));

    this.filteredOptions$ = this.formControl.valueChanges.pipe(
      map(currentValue => {
        const searchValue = this.getDisplay(currentValue);
        return searchValue && currentValue !== this.value
          ? this.mappedOptions.filter(o => this.searchCondition(o, searchValue))
          : [...this.mappedOptions];
      }),
    );
  }

  ngAfterViewInit(): void {
    //Use setTimeout to allow redraw

    setTimeout(() => {
      const option = this.mappedOptions.find(o => o.display === this.getDisplay(this.value));
      this.selectPossibleOption(option);
    });
  }

  writeValue(value: T | string): void {
    this.value = value;
    this.formControl.setValue(value);

    const option = this.mappedOptions.find(o => o.display === this.getDisplay(value));
    this.selectPossibleOption(option);
  }

  registerOnChange(onChange: typeof this.onChangeCallback) {
    this.onChangeCallback = onChange;
  }

  registerOnTouched(onTouched: typeof this.onTouched): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (!isDisabled) {
      this.formControl.enable();
    } else {
      this.formControl.disable();
    }
  }

  validate(control: AbstractControl): ValidationErrors {
    return null;
  }

  protected onChangeCallback = (_: string | T) => {};
  protected onTouched = () => {};

  protected onInputKeydown() {
    this.matOptions.filter(o => o.selected).forEach(o => o.deselect());
  }

  protected onInputEnter(e: KeyboardEvent, inputValue: string) {
    this.onInputSubmit(inputValue);
    e.preventDefault();
  }

  protected onInputBlur(e: FocusEvent, inputValue: string) {
    // The relatedTarget property returns the element related to the element that triggered the focus/blur event.
    // For onblur and onfocusout events, the related element is the element that GOT focus.
    if ((e.relatedTarget as HTMLElement)?.tagName !== 'MAT-OPTION') {
      // If the focus is not on the mat-option, then the user has clicked outside the autocomplete. Save the input value.
      this.onInputSubmit(inputValue);
    }
  }

  protected onInputSubmit(inputValue: string) {
    this.onTouched();
    const foundOption = this.mappedOptions.find(o => o.display === inputValue);
    this.selectPossibleOption(foundOption);
    this.onChange(foundOption?.value ?? inputValue);
  }

  protected onChange(value: string | T) {
    if (value !== this.value) {
      this.value = value;
      this.formControl.setValue(value);
      this.onChangeCallback(value);
    }
  }

  protected getDisplay = (item: T | string) => {
    const value = this.displayProperty && typeof item !== 'string' ? item?.[this.displayProperty] : item;
    return value ? String(value) : null;
  };

  private selectPossibleOption(option: WithDisplay<T> | null) {
    if (option) {
      this.matOptions?.find(o => o.value === option.value)?.select();
    }
  }

  private searchCondition(option: WithDisplay<T>, searchValue: string): boolean {
    return option.display.toLowerCase().includes(searchValue.toLowerCase());
  }
}

type WithDisplay<T> = {
  value: T;
  display: string;
};
