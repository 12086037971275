<!--
 Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited.
 Proprietary and confidential.
-->

<ng-container *ngIf="displaySectors$ | async as displaySectors">
  <ng-container *ngIf="showValid || displaySectors.length > 0; else hasInvalidSectors">
    <section class="sectors" [ngClass]="direction.toLowerCase()">
      <ng-container *ngFor="let sector of displaySectors; let i = index">
        <ng-container *ngIf="i < SHOW_MORE_LIMIT || showingMore || !showExpandButton">
          <article class="sector">
            <a [routerLink]="interactive ? [routePrefix, 'country', sector.country?.short] : null" [queryParams]="queryParams" [title]="showCountryPopup ? sector.country?.name : ''" data-toggle="tooltip" data-placement="top">
              <section class="d-flex align-items-center f32">
                <span class="flag {{ sector.country?.short.toLowerCase() }}"></span>
                <ng-container *ngIf="showCountryName">
                  <span>{{ sector.country.name }}</span>
                  <span class="mx-2">/</span>
                </ng-container>
              </section>
            </a>
            <a [class.cursor]="interactive" [routerLink]="interactive ? [routePrefix, 'industry', sector.industry?.name] : null" [queryParams]="queryParams" [descriptionTooltip]="showIndustryDescriptionPopup ? sector.industry?.name : ''">
              {{ sector.industry?.name }}
              <span *ngIf="showWeight && sector.weight < 1"> ({{ sector.weight * 100 | number: '0.0-0' }}) </span>
            </a>
          </article>
        </ng-container>
      </ng-container>

      <!-- Showing valid sectors currently but there are further to show (hidden due to invalid) -->
      <ng-container *ngIf="showValid && hasInvalid; then inProgress"></ng-container>
    </section>

    <button class="btn btn-link btn-expand" *ngIf="displaySectors.length > SHOW_MORE_LIMIT && showExpandButton" (click)="showingMore = !showingMore">
      <small>{{ showingMore ? 'Show Less' : 'Show More' }}</small>
    </button>
  </ng-container>

  <ng-template #hasInvalidSectors>
    <section class="sectors" [ngClass]="direction.toLowerCase()">
      <ng-container *ngIf="true; then inProgress"></ng-container>
    </section>
  </ng-template>

  <ng-template #inProgress>
    <span class="sector pending">&#9913; Pending Classification</span>
  </ng-template>
</ng-container>
