import { CommonModule } from '@angular/common';
import { Component, booleanAttribute, computed, input } from '@angular/core';
import { MaterialModule } from 'src/app/ui/material.module';

@Component({
  selector: 'fsn-tag',
  standalone: true,
  imports: [MaterialModule, CommonModule],
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  host: {
    '[class]': 'allClasses()',
  },
})
export class TagComponent {
  icon = input(false, { transform: booleanAttribute });
  noBorder = input(false, { transform: booleanAttribute });
  fixed = input(false, { transform: booleanAttribute });
  size = input<'small' | 'large'>('small');
  customClass = input('');
  protected allClasses = computed(() => ({
    'fs-next': true,
    'fsn-tag-truncated': !this.fixed(),
    'fsn-tag-no-border': this.noBorder(),
    'fsn-tag-small': this.size() === 'small',
    'fsn-tag-large': this.size() === 'large',
    [this.customClass()]: !!this.customClass(),
  }));
}
