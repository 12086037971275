import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { fromEvent, map, of, shareReplay, startWith, switchMap } from 'rxjs';
import { FFKey, featureFlags } from './feature-flags';
import { LaunchDarklyService } from './launchdarkly.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  private readonly ldService = inject(LaunchDarklyService);

  readonly allFlags$ = this.ldService.ldclient$.pipe(
    switchMap(ldclient =>
      ldclient
        ? fromEvent<LDFlagSet>(ldclient, 'change').pipe(
            startWith(ldclient.allFlags()),
            map(() => ldclient.allFlags()),
          )
        : of(),
    ),
    shareReplay(1),
  );

  /**
   * @param key the name of the flag.
   * @returns an observable that emits the value associated with the requested flag.
   */
  variation$<K extends FFKey>(key: K) {
    const flag = featureFlags[key];
    return this.ldService.ldclient$.pipe(
      switchMap(ldclient =>
        ldclient
          ? fromEvent(ldclient, `change:${key}`).pipe(
              startWith(ldclient.variation(key, flag.defaultValue)),
              map(() => ldclient.variation(key, flag.defaultValue)),
            )
          : of(),
      ),
      map(value => flag.validate(value)),
    );
  }
}

/**
 * Get a signal for a single feature flag. Requires an injection context.
 *
 * @param key the feature flag to watch
 * @return a read-only signal of the feature flag's state
 * @see {@link featureFlag$} for an observable version
 */
export const featureFlag = (key: FFKey) => {
  const flag = featureFlags[key];
  return toSignal(featureFlag$(key), { initialValue: flag.defaultValue });
};

/**
 * Get an observable for a single feature flag. Requires an injection context.
 *
 * @param key the feature flag to watch
 * @return an observable of the feature flag's state
 * @see {@link featureFlag} for a signal version
 */
export const featureFlag$ = (key: FFKey) => inject(FeatureFlagsService).variation$(key);
