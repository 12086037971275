// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CompanyService } from '../company/company.service';
import { AuthService } from '../core/auth/auth.service';
import { EntityService } from '../core/entity/entity.service';
import { AlertService } from '../shared/alert.service';
import { QuestionnaireResponse } from './questionnaire-response.model';
import { QuestionnaireResultsSectionScore } from './questionnaire-results/questionnaire-results.model';

export interface SaqRequestModel {
  itemId: number;
}

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireResponseService extends EntityService<QuestionnaireResponse> {
  constructor(
    protected companyService: CompanyService,
    protected authService: AuthService,
    protected alertService: AlertService,
    protected httpClient: HttpClient,
  ) {
    super(alertService, httpClient);
  }

  getAllByRecord$(recordId: number, expand: string[]): Observable<QuestionnaireResponse[]> {
    const req$ = super.getAll$({ record_id: recordId.toString(), expand });

    return this.handleGetOrCreateResponse(req$, {
      log: `Get ${this.pluralIdentifier()} by record(${recordId}) failed:`,
      nice: `Failed to get ${this.pluralIdentifier()}`,
    });
  }

  getAllByClient$(clientId: number, expand: string[]): Observable<QuestionnaireResponse[]> {
    const req$ = super.getAll$({ client_id: clientId.toString(), expand });

    return this.handleGetOrCreateResponse(req$, {
      log: `Get ${this.pluralIdentifier()} by client(${clientId}) failed:`,
      nice: `Failed to get ${this.pluralIdentifier()}`,
    });
  }

  getScores$(questionnaireResponseId: number): Observable<QuestionnaireResultsSectionScore[]> {
    const url = this.getWebApiEndpoint('scores', questionnaireResponseId);
    const req$ = this.httpClient.get<QuestionnaireResultsSectionScore[]>(url);
    return this.handleGetOrCreateResponse(req$, {
      log: `Get scores for ${this.pluralIdentifier()} (${questionnaireResponseId}) failed:`,
      nice: `Failed to get scores for ${this.pluralIdentifier()}`,
    });
  }

  exportByClient$(clientId: number): Observable<Blob> {
    const url = this.getWebApiEndpoint('export');
    const req$ = this.httpClient.get(url, { params: { client_id: clientId.toString() }, responseType: 'blob' });
    return this.handleGetOrCreateResponse(req$, {
      log: `Export ${this.pluralIdentifier()} by client(${clientId}) failed:`,
      nice: `Failed to export ${this.pluralIdentifier()}`,
    });
  }

  pluralIdentifier(): string {
    return 'questionnaire-responses';
  }

  singularIdentifier(): string {
    return 'questionnaire-response';
  }
}
