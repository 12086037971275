<!--
 Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited.
 Proprietary and confidential.
-->

<section class="file-upload-wrapper" [attr.data-text]="fileNames ?? placeholder">
  <input #fileInput type="file" class="form-control" name="file" [disabled]="disabled" [multiple]="multiple" />
  <div *ngIf="files" class="action">
    <button type="submit" class="btn btn-primary" (click)="clear()">Delete</button>
  </div>
</section>
