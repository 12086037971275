// @ts-strict-ignore
// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, combineLatest, shareReplay, Subject, takeUntil, map, tap } from 'rxjs';
import { Country } from 'src/app/country/country.model';
import { Industry } from 'src/app/industry/industry.model';
import { SectorListService } from './sector-list.service';

/**
 * Mapping of country and industry by id.
 */
type CountryIndustryMap = [Record<number, Country>, Record<number, Industry>];

@Component({
  selector: 'app-sector-list',
  templateUrl: './sector-list.component.html',
  styleUrls: ['./sector-list.component.scss'],
})
export class SectorListComponent implements OnChanges, OnDestroy {
  /**
   * The complete list of sectors that we want to display.
   */
  @Input()
  sectors: { country_id: number; industry_id: number }[];

  @Input()
  direction: 'HORIZONTAL' | 'VERTICAL' = 'VERTICAL';

  @Input()
  routePrefix = './';

  @Input()
  interactive = false;

  @Input()
  showWeight = false;

  @Input()
  showCountryName = false;

  @Input()
  showCountryPopup = true;

  @Input()
  showIndustryDescriptionPopup = true;

  @Input()
  showExpandButton = true;

  /**
   * When there are invalid sectors (pending classification), we can choose to always show the valid ones.
   */
  @Input()
  showValid = false;

  readonly SHOW_MORE_LIMIT = 1;

  showingMore = false;

  queryParams: Params;

  countriesIndustries$: Observable<CountryIndustryMap>;

  displaySectors$: Observable<{ country: Country; industry: Industry }[]>;

  onDestroy$ = new Subject<void>();

  hasInvalid = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly sectorListService: SectorListService,
  ) {
    this.route.queryParams.subscribe(queryParams => (this.queryParams = queryParams));

    // Setup observable, used in ngOnChanges. Don't subscribe here.
    this.countriesIndustries$ = combineLatest([
      this.sectorListService.countries$,
      this.sectorListService.industries$,
    ]).pipe(
      // The source is a long-living observable. We should cleanup when this component is destroyed.
      takeUntil(this.onDestroy$),
      map(([countries, industries]: [Country[], Industry[]]) => {
        // Get map of countries by id.
        const countriesById: Record<number, Country> = countries.reduce((mapping, country) => {
          mapping[country.id] = country;
          return mapping;
        }, {});

        // Get map of industries by id.
        const industriesById: Record<number, Industry> = industries.reduce((mapping, industry) => {
          mapping[industry.id] = industry;
          return mapping;
        }, {});

        const rv: CountryIndustryMap = [countriesById, industriesById];
        return rv;
      }),
      shareReplay(1),
    );
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change.sectors == null) {
      return;
    }

    this.displaySectors$ = this.countriesIndustries$.pipe(
      // Check the validity of sector's country and industry.
      map(([countriesById, industriesById]: CountryIndustryMap) =>
        this.sectors.map(sector => {
          const country = countriesById[sector.country_id];
          const industry = industriesById[sector.industry_id];
          return !country || !industry ? null : { country, industry };
        }),
      ),
      map(sectors => {
        const validSectors = sectors.filter(s => s != null);
        return { sectors, validSectors };
      }),
      tap(({ sectors, validSectors }) => (this.hasInvalid = sectors.length !== validSectors.length)),
      map(({ sectors, validSectors }) => {
        // Show any valid sectors.
        if (this.showValid) {
          return validSectors;
        } else {
          // Don't show any sectors until all are valid.
          return this.hasInvalid ? [] : sectors;
        }
      }),
      map(sectors => {
        // When showing sectors, ensure don't show duplicates.
        const uniqueSectors = sectors.filter((value, idx, arr) => {
          const idxOfFirstOccurrence = arr.findIndex(
            v => v.country.id === value.country.id && v.industry.id === value.industry.id,
          );
          return idx === idxOfFirstOccurrence;
        });
        return uniqueSectors;
      }),
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
