import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'fsn-databox',
  templateUrl: './databox.component.html',
  styleUrls: ['./databox.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class DataboxComponent {
  @HostBinding('class.fs-next') cls = true;
}
