// @ts-strict-ignore
// aquila:/1/IDI/1/168/AUS#australia-real-estate-services

import { Sector } from '../industry/industry.model';

/**
 * See https://www.notion.so/Custom-URIs-for-Aquila-sectors-e8750bdb81e14b8d9e523b0ca60a14b7?pvs=4
 */
type ParsedSectorV1 = {
  scheme: { name: 'aquila'; version: 1 };
  /** The block in the MRIO and satellites. */
  block?: {
    /** The block ID. For industries, this is `IDI`. */
    id: string;
    version: number;
  };
  /** The item within the block. For the IDI block, this is the industry. */
  item?: {
    /** The row within the block, e.g. the industry ID. */
    id: number;
  };
  /** The geographic scope of this item. */
  country?: { short: string };
  /** A human-readable description. Don't rely on this. */
  description?: string;
};

const pattern = /^aquila:\/1(?:\/[-a-zA-Z0-9]+)*(?:#[-a-z]+)?$/;
export const parseAquilaUri = (uri: string): ParsedSectorV1 => {
  if (!pattern.exec(uri)) {
    console.warn(`Invalid aquila URI: ${uri}`);
    return null;
  }
  const [, path, description] = uri.split(/:|#/);
  const [, , block, blockVersion, index, country] = path.split('/');
  return {
    scheme: { name: 'aquila', version: 1 },
    ...(block && blockVersion && { block: { id: block, version: Number(blockVersion) } }),
    ...(index && { item: { id: Number(index) } }),
    ...(country && { country: { short: country } }),
    ...(description && { description }),
  };
};

export const toAquilaUriIdiV1 = (sector: Sector) => `aquila:/1/IDI/1/${sector.industry.id}/${sector.country.short}`;
