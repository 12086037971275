// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import packageInfo from 'package.json';
import { ClientService } from 'src/app/client/client.service';
import { featureFlag } from 'src/app/feature-toggles/feature-flags.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  host: { '[class.top-nav]': 'topNav()' },
})
export class FooterComponent {
  readonly topNav = featureFlag('release-top-nav');
  version: string = packageInfo.version;

  readonly client = toSignal(inject(ClientService).selectedClient$);
}
