export interface Flag<T, D = T> {
  readonly defaultValue: D;
  /**
   * Verify that the flag value is the expected type.
   * @return the value, or the default value if validation fails.
   */
  validate(value: unknown): T | D;
}

export class BooleanFlag implements Flag<boolean> {
  constructor(readonly defaultValue: boolean = false) {}
  validate(value: unknown) {
    return typeof value === 'boolean' ? value : this.defaultValue;
  }
}

export class StringFlag implements Flag<string> {
  constructor(readonly defaultValue: string = '') {}
  validate(value: unknown) {
    return typeof value === 'string' ? value : this.defaultValue;
  }
}

export class EnumFlag<T extends string> implements Flag<T, T | undefined> {
  constructor(
    readonly choices: readonly T[],
    readonly defaultValue: T | undefined = undefined,
  ) {}
  /**
   * Verify that the flag value is the expected type.
   * @return the value, or the default value if validation fails.
   * @throws if validation fails and there is no configured default.
   */
  validate(value: unknown): T {
    const choice = this.choices.find(s => s === value);
    if (choice !== undefined) return choice;
    if (this.defaultValue !== undefined) return this.defaultValue;
    throw Error(`Inlvaid variation`);
  }
}

export class NumberFlag implements Flag<number> {
  constructor(readonly defaultValue: number = 0) {}
  validate(value: unknown) {
    return typeof value === 'number' ? value : this.defaultValue;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MultivariateFlag = BooleanFlag | StringFlag | EnumFlag<any> | NumberFlag;

/** Special multivariate flag type that has a well-defined workflow in LaunchDarkly. */
export class MigrationFlag extends EnumFlag<MigrationStep> {
  constructor(defaultValue: MigrationStep) {
    super(MIGRATION_STEPS, defaultValue);
  }
}

/** Flag keys are kebab-case and start with a known prefix. */
type FlagKey<P extends Lowercase<string>> = `${P}-${Lowercase<string>}`;

/** Flag prefixes match variation types. */
type FlagSet<P extends Lowercase<string>, V extends Flag<unknown>> = Record<FlagKey<P>, V>;

type ReleaseFlags = FlagSet<'release', BooleanFlag>;
type ExperimentFlags = FlagSet<'experiment', MultivariateFlag>;
type MigrationFlags = FlagSet<'migration', MigrationFlag>;
type KillSwitchFlags = FlagSet<'kill-switch', BooleanFlag>;
type CustomFlags = FlagSet<'configure' | 'show' | 'allow', MultivariateFlag>;

/** Short-lived flags that help us to _change_ the platform. */
export type TemporaryFlags = ReleaseFlags & ExperimentFlags & MigrationFlags;

/** Long-lived flags that help us to _run_ the platform. */
export type PermanentFlags = KillSwitchFlags & CustomFlags;

const MIGRATION_STEPS = ['off', 'dualwrite', 'shadow', 'live', 'rampdown', 'complete'] as const;
type MigrationStep = (typeof MIGRATION_STEPS)[number];
