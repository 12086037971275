<!--
 Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited.
 Proprietary and confidential.
-->

<section class="d-flex align-items-center">
  <span class="risk-indicator rating-{{ rating | lowercase }}"></span>
  <span *ngIf="showLabel" class="risk-label ml-2">{{ RISK_RATING[rating] }}</span>
</section>
