// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnChanges {
  @Input()
  title: string;

  @Input()
  visible: boolean;

  @Input()
  cancellable = true;

  @Output()
  visibleChange = new EventEmitter<boolean>();

  @Output()
  confirmed = new EventEmitter<boolean>();

  @ViewChild('myModal')
  modal: ElementRef;

  ngOnChanges(changes: SimpleChanges) {
    if (this.modal) {
      const jqModal = $(this.modal.nativeElement);
      if (changes.visible?.currentValue) {
        jqModal.modal({ backdrop: 'static', show: true });
      } else {
        jqModal.modal('hide');
      }
    }
  }

  cancel(): void {
    this.confirmed.emit(false);
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  ok(): void {
    this.confirmed.emit(true);
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }
}
