// @ts-strict-ignore
// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { TabComponent } from './tab/tab-component';

@Component({
  selector: 'app-tab-strip',
  templateUrl: './tab-strip-component.html',
  styleUrls: ['./tab-strip-component.scss'],
})
export class TabStripComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  @Input()
  preload = false;

  protected selectedTab: TabComponent;

  ngAfterContentInit(): void {
    this.tabs.forEach(tab => (tab.loaded = this.preload && tab.preload));

    const activeTabs = this.tabs.filter(tab => tab.active);
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(selectedTab: TabComponent) {
    this.selectedTab = selectedTab;
    this.tabs.toArray().forEach(tab => (tab.active = false));
    this.selectedTab.active = true;
    this.selectedTab.loaded = true;
  }
}
